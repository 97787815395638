<template>
  <CycleHeader :media="media" myzone>
    <CycleHeroCardMyzone :heading="cardTitle" :name-data="personalData" :empty-name="emptyName" />
  </CycleHeader>
</template>

<script>
import { default as PersonalInfo } from './gql/mypersonalinfo.gql';

export default {
  name: 'CycleHeaderMyzoneSitecore',
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  props: {
    fields: {
      type: Object,
      required: false,
    },
  },
  computed: {
    media() {
      return this.fields.media?.value;
    },
    cardTitle() {
      return this.fields.cardTitle?.value || 'Welkom';
    },
    emptyName() {
      return this.fields.emptyName?.value;
    },
  },
  apollo: {
    personalData: {
      query: PersonalInfo,
      variables() {
        return {};
      },
      error(error) {
        this.error = error;
      },
      loadingKey: 'loadingQueriesCount',
    },
  },
};
</script>

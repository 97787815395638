<template>
  <CycleCookieConsent
    :heading="fields.heading.value"
    :button-approve-text="fields.buttonApproveText.value"
    :link-decline-text="fields.linkDeclineText.value"
    v-if="isClient"
  >
    <template slot="jss-content">
      <sc-rich-text :field="fields.bodytext" />
    </template>
  </CycleCookieConsent>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CycleCookieConsentSitecore',
  components: {
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isClient: false,
    };
  },
  mounted() {
    this.isClient = true; // Mounted hook is not triggered in SSR
  },
};
</script>

<template>
  <FunnelLiability
    v-bind="textContent"
    :antiForgeryToken="antiForgeryToken"
    :dataSource="dataSource"
  />
</template>

<script>
import Cycle from '@cfpa/cycle';
import { mapValues } from 'lodash';

export default {
  name: 'FunnelLiabilitySitecore',
  components: {
    FunnelLiability: Cycle.FunnelLiabilitySitecore,
  },
  props: {
    fields: {
      type: Object,
    },
    params: {
      type: Object,
    },
    rendering: {
      type: Object,
    },
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value);
    },
    antiForgeryToken() {
      return this.rendering.antiForgeryToken?.value;
    },
    dataSource() {
      return this.rendering.dataSource;
    },
  },
};
</script>

<template>
  <CycleHeading v-if="fields.title.value" :weight="1">
    <sc-text :field="fields.title" />
  </CycleHeading>
</template>
<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CycleHeadingSitecore',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

import i18ninit from './i18n';
import { createApp } from './createApp';
import config from './temp/config';

/* eslint-disable no-underscore-dangle */

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide the window.__JSS_STATE__ object
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__: any = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
// i did remove it .... -- RH -  2020-05-15
// Has a shitload of typescript issues as the maintainer of Vue18n refuses to merge:
// - https://github.com/panter/vue-i18next/pull/63

// @ts-ignore
i18ninit(initLanguage).then((i18n) => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root');

  const initialState = __JSS_STATE__ || undefined;

  const { app } = createApp(initialState, i18n);

  if (rootElement) {
    app.$mount(rootElement);
  } else {
    console.error('main.ts: app.$mount rootElement #root not found');
  }
});

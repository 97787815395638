<template>
  <CycleNavigation :items="items" />
</template>
<script>
import { pathOr } from 'ramda';

export default {
  name: 'CycleNavigationSitecore',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    items() {
      return pathOr([], ['fields', 'items'], this);
    },
  },
};
</script>

<template>
  <CycleSubGrid>
    <!-- <sc-placeholder name="jss-grid" :rendering="rendering" /> -->

    <template v-for="(grid, index) in gridPlaceholder">
      <component :is="grid" :key="`grid${index}`" />
    </template>
  </CycleSubGrid>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CycleSubGridSitecore',
  components: {
    ScPlaceholder: Placeholder,
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  withPlaceholder: {
    // you can alias the computed prop name for the placeholder or pass an array of placeholders
    placeholders: {
      placeholder: 'jss-grid',
      computedPropName: 'gridPlaceholder',
    },
  },
};
</script>

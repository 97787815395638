<template>
  <PolicyDetail v-bind="textContent" />
</template>

<script>
import Cycle from '@cfpa/cycle';
import { mapValues } from 'lodash';

export default {
  name: 'CyclePolicyDetailSitecore',
  components: {
    PolicyDetail: Cycle.CyclePolicyDetailSitecore,
  },
  props: {
    fields: {
      type: Object,
    },
    params: {
      type: Object,
    },
    rendering: {
      type: Object,
    },
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value);
    },
  },
};
</script>

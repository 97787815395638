<template>
  <CycleMyInsurance
    :href="href"
    :target="target"
    :href-detail="hrefDetail"
    :target-detail="targetDetail"
    :portfolioinfo="portfolioInfo || {}"
  />
</template>

<script>
import { default as PolicyInfo } from './gql/portfolioinfo.gql';

export default {
  name: 'CycleMyInsuranceSitecore',
  provide() {
    return {
      statusLabels: this.statusLabels,
    };
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  props: {
    fields: {
      type: Object,
      required: false,
    },
  },
  computed: {
    linkDetail() {
      return this.fields.linkDetail.value;
    },
    hrefDetail() {
      return this.linkDetail?.href || '';
    },
    targetDetail() {
      return this.linkDetail?.target || '_self';
    },
    link() {
      return this.fields.link.value;
    },
    href() {
      return this.link?.href || '';
    },
    target() {
      return this.link?.target || '_self';
    },
    statusLabels() {
      return (
        this.fields.statusLabels?.value || {
          statusInProgress: 'In behandeling',
          statusSuspended: 'Tijdelijk stopgezet',
        }
      );
    },
  },
  apollo: {
    portfolioInfo: {
      query: PolicyInfo,
      variables() {
        return {};
      },
      error(error) {
        this.error = error;
      },
      loadingKey: 'loadingQueriesCount',
      update(data) {
        return data;
      },
    },
  },
};
</script>

<template>
  <CycleHeader :media="media">
    <CycleHeaderPanel
      v-if="hasBadge"
      :title="paneltitle"
      :badgetext="panelbadgetext"
      :bodytext="panelbodytext"
      :links="links"
    />
    <template v-else slot="jss-header-main">
      <sc-placeholder name="jss-header-main" :rendering="rendering" />
    </template>
  </CycleHeader>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { pathOr } from 'ramda';

export default {
  name: 'CycleHeaderSitecore',
  components: {
    ScPlaceholder: Placeholder,
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    media() {
      return pathOr(null, ['fields', 'media'], this);
    },
    links() {
      return pathOr(null, ['fields', 'links', 'value'], this);
    },
    paneltitle() {
      return pathOr(null, ['fields', 'paneltitle', 'value'], this);
    },
    panelbadgetext() {
      return pathOr(null, ['fields', 'panelbadgetext', 'value'], this);
    },
    panelbodytext() {
      return pathOr(null, ['fields', 'panelbodytext', 'value'], this);
    },
    hasBadge() {
      return this.paneltitle || this.panelbadgetext || this.panelbodytext;
    },
  },
};
</script>

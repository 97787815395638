<template>
  <CycleContentBlock v-bind="modifierList">
    <template slot="header">
      <CycleIcon :modifiers="[fields.icon.value]" v-if="fields.icon.value" hexagon />
      <CycleHeading v-if="fields.heading.value"><sc-text :field="fields.heading"/></CycleHeading>
    </template>
    <sc-rich-text tag="span" :field="fields.bodytext" />
    <slot />
    <template slot="footer">
      <CycleNav buttons v-if="navButtons">
        <CycleButtonLink
          v-for="(item, index) in links"
          :key="index"
          :href="href(item)"
          :target="target(item)"
          modifiers="cta, secondary"
        >
          {{ item.title }}
        </CycleButtonLink>
      </CycleNav>
      <template v-else>
        <CycleButtonLink
          v-for="(item, index) in links"
          :key="index"
          :href="href(item)"
          :target="target(item)"
          modifiers="cta, secondary"
        >
          {{ item.title }}
        </CycleButtonLink>
      </template>
    </template>
  </CycleContentBlock>
</template>
<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { pathOr } from 'ramda';

export default {
  name: 'CycleContentBlockSitecore',
  components: {
    ScText: Text,
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    navButtons() {
      // return pathOr(false, ['fields', 'navButtons', 'value'], this);
      return this.fields?.navButtons?.value;
    },
    links() {
      return pathOr(false, ['fields', 'links', 'value'], this);
    },
    modifiers() {
      return pathOr('', ['params', 'modifiers'], this);
    },
    transparent() {
      return pathOr(0, ['params', 'transparent'], this);
    },
    modifierList() {
      const modifiers = {};
      if (this.modifiers && this.modifiers.length) {
        modifiers[this.modifiers] = true;
      }
      if (this.transparent === 0 || this.transparent === '0') {
        modifiers['contrastColor'] = true;
      }
      return modifiers;
    },
    footerComponent() {
      return this.navButtons ? 'nav' : 'fragment';
    },
  },
  methods: {
    href(item) {
      return (item && item.href) || '';
    },
    target(item) {
      return (item && item.target) || '';
    },
  },
};
</script>

<template>
  <CoreUI :rendering="route" />
</template>

<script>
import CoreUI from './CoreUI.vue';

export default {
  name: 'Layout',
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },
  metaInfo() {
    return {
      title:
        (this.route.fields && this.route.fields.pageTitle && this.route.fields.pageTitle.value) ||
        'Page',
    };
  },
  components: {
    CoreUI,
  },
};
</script>

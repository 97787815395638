<template>
  <CycleCompareCoverage>
    <template v-for="(compare, index) in jssComparison">
      <component :is="compare" :key="`compare${index}`" />
    </template>
  </CycleCompareCoverage>
</template>
<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CycleCompareCoverageSitecore',
  components: {
    ScPlaceholder: Placeholder,
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  withPlaceholder: {
    // you can alias the computed prop name for the placeholder or pass an array of placeholders
    placeholders: {
      placeholder: 'jss-comparison',
      computedPropName: 'jssComparison',
    },
  },
};
</script>

<template>
  <CycleLinkCard :href="href" :target="target" :text="text" />
</template>

<script>
export default {
  name: 'CycleLinkCardSitecore',
  props: {
    fields: {
      type: Object,
      required: false,
    },
  },
  computed: {
    link() {
      return this.fields.link.value;
    },
    href() {
      return this.link?.href || '';
    },
    target() {
      return this.link?.target || '_self';
    },
    text() {
      return this.link?.text || '_self';
    },
  },
};
</script>

<template>
  <CycleFooter>
    <div v-if="menus" slot="jss-footer-right" class="footergrid__nav">
      <div v-for="menu in menus" :key="menu.id" class="footergrid__panel">
        <CycleHeading v-if="menu.title" :weight="4">
          {{ menu.title }}
        </CycleHeading>
        <CycleList v-if="menu.children && menu.children.length" modifiers="nomarker">
          <CycleListItem v-for="menuItem in menu.children" :key="menuItem.id">
            <CycleLink :href="menuItem.url" :target="menuItem.target">
              {{ menuItem.title }}
            </CycleLink>
          </CycleListItem>
        </CycleList>
      </div>
    </div>

    <CycleList
      slot="jss-footer-bottom"
      v-if="bottomMenuItems && bottomMenuItems.length"
      modifiers="bar"
    >
      <CycleListItem v-for="bottomMenuItem in bottomMenuItems" :key="bottomMenuItem.id">
        <CycleLink
          v-if="bottomMenuItem.url"
          :href="bottomMenuItem.url"
          :target="bottomMenuItem.target"
        >
          {{ bottomMenuItem.title }}
        </CycleLink>
        <span v-else>
          {{ bottomMenuItem.title }}
        </span>
      </CycleListItem>
    </CycleList>
  </CycleFooter>
</template>
<script>
export default {
  name: 'CycleFooterSitecore',
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    menus() {
      const menuItems = this.fields.data?.menus?.items || [];

      return !menuItems
        ? null
        : menuItems.map((menu) => {
            const id = menu.id;
            const title = menu?.title?.value || '';
            const children = (menu?.children || []).filter(this.isValidMenuItem).map((child) => {
              const id = child.id;
              const title = child.title.value;
              const url = child.link.url;
              const target = child.link.target || null;
              const className = child.link.className || null;

              return { id, title, url, target, className };
            });

            return { id, title, children };
          });
    },
    bottomMenuItems() {
      const bottomMenuItems = this.fields.data?.links?.items || [];
      return bottomMenuItems.map((item) => {
        const id = item.id;
        const title = item.title.value;
        const url = item.link ? item.link.url : null;
        const target = item.link ? item.link.target : null;

        return { id, title, url, target };
      });
    },
  },
  methods: {
    isValidMenuItem(item) {
      return item.id && item.title && item.title.value && item.link && item.link.url;
    },
  },
};
</script>

<template>
  <CycleCookieToggle>
    <template #heading>
      <sc-text :field="fields.heading" />
    </template>
    <template #accept-text>
      <sc-rich-text :field="fields.acceptText" />
    </template>
    <template #decline-text>
      <sc-rich-text :field="fields.declineText" />
    </template>
  </CycleCookieToggle>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CycleCookieToggleSitecore',
  components: {
    ScText: Text,
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<template>
  <div class="coreui">
    <component
      v-for="(comp, index) in jssNav"
      :is="comp"
      :key="`compNav${index}`"
      class="coreui__navigation"
    />
    <component v-for="(comp, index) in jssHeader" :is="comp" :key="`compHeader${index}`" />
    <main class="coreui__main">
      <component v-for="(comp, index) in jssMain" :is="comp" :key="`compMain${index}`" />
    </main>
    <component
      v-for="(comp, index) in jssFooter"
      :is="comp"
      :key="`compFooter${index}`"
      class="coreui__footer"
    />
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import '@aon/aon-core-framework/src/styles/aon.scss';

export default {
  name: 'CoreUI',
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Placeholder,
  },
  withPlaceholder: {
    placeholders: [
      {
        placeholder: 'jss-navigation',
        computedPropName: 'jssNav',
      },
      {
        placeholder: 'jss-header',
        computedPropName: 'jssHeader',
      },
      {
        placeholder: 'jss-main',
        computedPropName: 'jssMain',
      },
      {
        placeholder: 'jss-footer',
        computedPropName: 'jssFooter',
      },
    ],
  },
};
</script>

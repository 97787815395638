<template>
  <CycleSection :sectionstyle="bemClass">
    <template slot="header" v-if="fields.heading.value">
      <CycleHeading :weight="2"> <sc-text :field="fields.heading"/></CycleHeading>
    </template>
    <sc-placeholder
      v-if="!!rendering.placeholders['jss-content']"
      name="jss-content"
      :rendering="rendering"
    />
    <sc-placeholder
      v-if="!!rendering.placeholders['jss-section']"
      name="jss-section"
      :rendering="rendering"
    />
  </CycleSection>
</template>

<script>
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CycleSectionSitecore',
  components: {
    ScPlaceholder: Placeholder,
    ScText: Text,
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    bemClass() {
      return this.params && this.params.sectionStyle;
    },
  },
};
</script>
